.box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 12px 24px;

    background: #E4EEFD;
    border: 1px solid #1A75F5;
    border-radius: 2px;
}

.dark {
    background: #051731;
    border: 1px solid #1A75F5;
}