* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  font-weight: 700;
  color: #56b7f4;
}

a:visited {
  color: #56b7f4;
  text-decoration: none;
}

button {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  font-family: Roboto, sans-serif;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;

  border: none;

  transition:
    background 250ms ease-in-out,
    transform 150ms ease;

  -webkit-appearance: none;
  -moz-appearance: none;
}

.darkTheme {
  --table_header: #263648;
  --table_border: 1px solid #263648;
  --main_border: 1px solid #2f3c4a;
  --tag_border: 1px solid #46515e;
  --iconColor: white;
  --highlight: #2f3c4a;
  --bg_secondary: #222f40;
  --bg: #131e2b;
  --curtainColor: #424242;
  --titleBGColor: hsla(0, 0%, 100%, 0.15);
  --textColor: white;
  --navbarBG: #424242;
  --buttonBG: #6666;
  --filterIconColor: invert(0);
  --colorH1: #ffb400;
  --coloBlock: linear-gradient(180deg, rgb(0, 87, 184) 50%, rgb(255, 215, 0) 50%);
}

.lightTheme {
  --table_header: #e5e5e5;
  --table_border: 1px solid #e5e5e5;
  --tag_border: 1px solid #e5e5e5;
  --main_border: 1px solid #e5e5e5;
  --bg: white;
  --iconColor: black;
  --highlight: #f4f4f4;
  --bg_secondary: white;
  --curtainColor: #e7e4e4;
  --titleBGColor: rgba(30, 37, 48, 0.07);
  --textColor: #000;
  --navbarBG: white;
  --buttonBG: #e7e4e4;
  --filterIconColor: invert(0.7);
  --colorH1: #1b49af;
  --coloBlock: linear-gradient(180deg, rgb(0, 87, 184) 50%, rgb(255, 215, 0) 50%);
}

button:disabled {
  cursor: not-allowed;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*https://stackoverflow.com/a/486571*/
.hiddenText {
  overflow: hidden;
  display: inline-block;

  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.commonLinkText {
  cursor: pointer;

  color: #2eb4ff;
  text-overflow: ellipsis;
}

.copy-to-clipboard-button > span {
  color: white;
}

/* Table styles */
.commonRow {
  display: flex;
  justify-content: space-between;

  border: var(--table_border);
  border-top: none;
  background-color: var(--bg);
}

.commonCell {
  overflow: hidden;
  display: flex;
  align-items: center;

  padding: 15px 24px;

  font-size: 14px;
  /*border: 1px solid snow;*/
  text-overflow: ellipsis;
}

.commonButtonsCell {
  display: flex;
  align-items: center;
  justify-content: center;
  /*border: 1px solid orchid;*/
}

.icon {
  color: var(--iconColor) !important;
}

.code-toolbar {
  position: relative;
}

.toolbar {
  position: absolute;
  top: 10px;
  right: 20px;
}

.copy-to-clipboard-button {
  font-size: 20px;

  background-color: #011727;
}
