.tableCell {
  padding: 10px 10px 10px 24px !important;
}

.lensTableCell {
  padding: 10px 6px 10px 2px !important;
  max-width: 10vw;
}

.lens {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 4px;
}

.lensActive {
  composes: lens;
  background-color: var(--color-accent-500);
}

.tooltipComponent {
  margin-left: 5px;
  color: var(--color-text-link);
  font-weight: var(--font-weight-bold);
}

.button {
  all: unset;
}
