.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65px;
  padding-bottom: 10px;
}

.editModeWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
