.pageWrapper {
  width: 100%;
  max-width: 1024px;
  padding: 10px 46px 40px 48px;

  word-break: break-word;

  margin: auto;
}

.videoWrapper {
  margin-bottom: 10px;
}

.iframe {
  width: 100%;
  height: 453px;
  border: none;
}

.videoTitle {
  padding-top: 19px;
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.videoDescription {
  margin-bottom: 38px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
