.container {
  margin-bottom: 24px;
}

.headerContainer {
  display: flex;
  white-space: nowrap;
  margin-bottom: 24px;
}

.filtersContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.combobox {
  width: 500px;
}

.textSecondary {
  color: #8c8889;
}

.textSecondaryDark {
  color: #a3a8af;
}

.title {
  font-size: 14px;
  padding-bottom: 7px;
  composes: textSecondary;
}

.titleDark {
  composes: title;
  composes: textSecondaryDark;
}
