.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background: var(--color-neutral-dark-50);
  color: #332e2f;
  font-size: var(--font-size-xs);
}

:global(.dark-mode) .container {
  background: var(--color-dark-mode-500);
  color: var(--color-dark-mode-200);
}
