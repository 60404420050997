.error {
    color: #F51A51;
}

.text {
    color: #19E08D;
}

.hide {
    display: none;
}
