.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tagsContainer {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
