.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin-top: 5px;
}
