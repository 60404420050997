.filtersWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

  margin: 17px 0 24px;
}

.item {
  list-style-type: none;
}

.list {
  display: flex;
  justify-content: space-between;

  margin-bottom: 41px;
}

.subtitleItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #999999;
}

.column:first-child {
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.column:not(:first-child) {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.column {
  padding: 25px 0 10px 25px;
  background-color: var(--bg);
}

.column:nth-child(9) {
  padding: 15px 0 10px 0;
}

.containerTable > .columnText {
  width: 100%;
  min-width: 970px;
}

.text {
  margin: 11px 0 25px;
}

.modalContainer > div {
  border: 4px solid red;
}

.tableRow,
.contentModalTable {
  display: flex;

  border: var(--table_border);
  border-top: none;
}

.contentModalTable {
  flex-direction: column;

  padding: 30px 24px 17px;
}

.contentModalBlock {
  margin-bottom: 27px;
}

.contentModalSubtitle {
  margin-bottom: 10px;
}

.contentModalDescription {
  margin-top: 10px;
}

.listPreloader {
  display: flex;
  justify-content: center;

  margin-bottom: 50px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
