.pagination {
  display: flex;
  align-items: center;

  margin-top: 20px;
  margin-bottom: 40px;
}

.selectPagination {
  display: flex;
  align-items: center;
}

.selectPagination p {
  margin: 0 6px 0 12px;
}
