.search {
  margin-top: 24px;
  margin-bottom: 20px;
}

.selectsContainer {
  display: flex;
  gap: 20px;
}

.combobox {
  width: 300px;
}
