.homeworkUserHeader {
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  height: 48px;
  margin-top: 8px;
  padding: 12px 24px;

  background: var(--table_header);
  border-radius: 4px 4px 0 0;
}

.linksBlock {
  display: flex;
  justify-content: space-between;

  height: 90px;
  padding: 16px 24px 25px;

  background: var(--bg);
  border: var(--table_border);
}

.linkB {
  width: 47%;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.linkDescription {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 19px;
  color: #fff;

  opacity: 0.5;
}

.homework {
  padding: 12px 24px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 22px;

  background: var(--bg);
  border: var(--table_border);
  border-top: none;
}

.homeworkHeader {
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
}

.plus {
  cursor: pointer;

  display: inline-block;

  width: 20px;

  color: #ff1b54;
}

.status {
  padding-top: 32px;
}

.h3 {
  display: flex;
  justify-content: space-between;

  padding-top: 8px;
}

.studentCom {
  margin-top: 16px;
  padding: 12px;

  border: 1px solid #263648;
}

.answerBlock {
  display: flex;

  padding: 18px 0 6px;
}

.input {
  flex-grow: 1;

  height: 36px;
  margin-right: 24px;
  padding-left: 12px;

  color: var(--textColor);

  background: var(--table_header);
  border: none;
}

.button2 {
  width: 132px;
  height: 36px;
  margin-left: 24px;

  color: white;

  background: #263648;
  border: none;
  border-radius: 30px;
}

.blackText {
  color: black;
}
