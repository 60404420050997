.rowBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 12px 20px;

    border-bottom: 1px solid #e0e0e0;
}

.studentBox {
    display: flex;
    gap: 20px;
    align-items: center;

    width: 400px;
    height: 100%;
}

.avatar {
    cursor: pointer;

    object-fit: cover;
    border-radius: 50%;
}