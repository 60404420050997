.cthBox {
  display: flex;
  justify-content: space-between;

  width: 100%;

  background: var(--table_header);
}

.cthItem {
  padding: 14px 24px 12px;

  background: var(--table_header);
  /*border: 1px solid yellow;*/
}
