.button {
  width: 123px;
  height: 36px;

  background-color: #f51a51;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 18px rgba(245, 26, 81, 0.35), inset 0 1px 0 rgba(255, 255, 255, 0.3);
}
.button:hover {
  background-color: #f03564;
}

.button:active {
  background-color: #f51a51;
}
