.tableCell {
  padding: 10px 10px 10px 24px;
}

.opacityText {
  opacity: 0.5;
}

.buttonsContainer {
  display: flex;
}
