.App {
  height: 100vh;

  color: white;

  background: #182636;
}

@media screen and (max-width: 600px) {
  .App {
    background-size: 300%;
  }
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

.MuiDataGrid-cell {
  overflow: auto;
  display: flex !important;
  align-items: center;

  max-height: fit-content !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  line-height: 16px !important;
  white-space: initial !important;
}
