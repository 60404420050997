.search {
  margin-top: 24px;
  margin-bottom: 20px;
}

.selectsContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}
