.tableCell {
  padding: 10px 10px 10px 24px;
  word-break: break-word;
}

.tableRoot {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.search {
  margin-top: 24px;
  margin-bottom: 20px;
}
