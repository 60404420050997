.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-accent-500);
  color: var(--color-neutral-light-50);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.secondaryColor {
  composes: notification;
  background-color: var(--color-neutral-dark-300);

  :global(.dark-mode) & {
    background-color: var(--color-dark-mode-400);
  }
}
