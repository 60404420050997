.workItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;
  padding-bottom: 15px;
}

.container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.avatar {
  margin-left: 15px;
}

.workInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.textMain {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}

.textMainDark {
  composes: textMain;
  color: #fffefe;
}

.textSecondary {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.container {
  display: flex;
  justify-content: space-between;
}

.submitButton {
  margin-right: 10px !important;
}
