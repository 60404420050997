.tableRoot {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableCell {
  padding: 10px 10px 10px 24px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  width: 150px;
}
