.controlsContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.combobox {
  margin-bottom: 25px;
  width: 350px;
}

.cardContent {
  padding: 0 !important;
}

.chatContainer {
  border-bottom: none !important;
}

:global(.dark-mode) .chatContainer {
  border: 1px solid var(--color-dark-mode-400) !important;
  border-bottom: none !important;
}

.chatHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid var(--color-neutral-dark-100);
}

:global(.dark-mode) .chatHeader {
  border-bottom: 1px solid var(--color-dark-mode-400);
}

.scrollbar {
  height: 456px;
}

.contentContainer {
  min-height: 456px;
  padding: 15px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
}

.messageBubble {
  margin: 0 20px;
}

.dialogContent {
  margin-bottom: 30px;
}
