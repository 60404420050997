.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkBoxContainer {
  align-self: flex-end;
}

.labelCursor div label:hover {
  cursor: pointer;
}

.divider {
  margin: 20px 0 !important;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
