.tableRoot {
  margin-bottom: 24px;
}

.reaction {
  align-items: center !important;
}

.reactionsContainer {
  display: flex;
  gap: 6px;
}
