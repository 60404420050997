.scBox {
  cursor: pointer;

  position: absolute;
  top: -52px;

  width: 100%;
  height: 52px;

  opacity: 0.5;
}

.scBox:hover {
  background-color: rgba(250, 250, 210, 0.1);
}

.scIcon {
  position: absolute;
  top: 13px;
}
