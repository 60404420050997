.unread {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.search {
  margin-top: 24px;
  margin-bottom: 12px;
}

.checkboxesContainer {
  display: flex;
  gap: 24px;
}
