.dividerContainer {
  padding: 12px 24px;
}

.divider {
  border: 0.5px solid var(--color-neutral-dark-100);

  :global(.dark-mode) & {
    border: 0.5px solid var(--color-dark-mode-400);
  }
}

.activeLink {
  color: var(--color-accent-500);
  opacity: 1;
  background: rgb(245 26 81 / 10%);
  border-radius: 0 24px 24px 0;
}

.linkItem {
  text-decoration: none;
}

menu {
  width: 100%;
  padding-bottom: 12px;

  & .menuBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 60%;

    & .menuItem {
      cursor: pointer;

      position: relative;

      overflow: hidden;
      display: flex;
      gap: 12px;
      align-items: center;

      max-width: 100%;
      padding: 6px 24px;

      font-size: 16px;
      font-weight: 500;
      color: var(--color-text-primary);
      text-decoration: none;
      white-space: nowrap;

      transition: 0.2s ease-in;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        width: 0;
        height: 100%;

        opacity: 0.1;
        background-color: var(--color-neutral-dark-500);
        border-radius: 0 24px 24px 0;

        transition: 0.2s ease-in;

        :global(.dark-mode) & {
          opacity: 0.2278;
          background-color: white;
        }
      }

      > span {
        display: none;
      }

      &.sidebarOpen {
        > span {
          display: inline;
        }
      }

      &:focus-visible {
        outline: none;
      }

      &:not(.activeLink):hover,
      &:not(.activeLink):focus {
        &::before {
          width: 100%;
          height: 100%;
        }
      }
    }

    & .menuItem.activeLink {
      color: var(--color-accent-500);
    }
  }
}

.sidebar {
  z-index: auto;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  > menu {
    flex-grow: 1;
  }

  &.active {
    position: static;
    left: 0;
  }

  :global(.dark-mode) &.active {
    background: #182636;
    box-shadow: 1px 0 0 rgb(84 84 84 / 50%);
  }
}

.switchBlock {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 24px;
}

.cursorPointer {
  cursor: pointer;
}
