.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: normal;
  white-space: nowrap;
}

.dateAndCheckboxContainer {
  display: flex;
  align-items: center;
  height: 27px;
}

.redText {
  color: red;
}
