.commentsContainer {
  padding-top: 35px;
}

.videoCommentsTitle {
  padding-bottom: 17px;
}

.sortBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sortContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}

.sortSelect {
  width: 220px !important;
}

.commentsBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
