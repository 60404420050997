.main {
  scroll-behavior: smooth;

  overflow: auto;

  height: 100%;

  padding: 34px 24px 100px calc(var(--sidebar-closed-width) + 24px);

  &.open {
    padding-left: calc(var(--sidebar-open-width) + 24px);

    > div[class*='scrollbar'] {
      padding-left: calc(var(--sidebar-open-width) + 4px);
    }
  }

  > div[class*='scrollbar'] {
    padding-left: calc(var(--sidebar-closed-width) + 4px);
  }

  > div[class*='viewport'] {
    > div {
      display: initial !important;
    }
  }
}

.backBlurBlock {
  @media screen and (width <= 1280px) {
    position: fixed;
    z-index: 25;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    backdrop-filter: blur(25px);
  }
}
