.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.copyButton,
.deleteButton,
.updateButton {
  position: absolute;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  color: #000000;

  background: #ffffff;
  border-radius: 2px;
}

.copyButton {
  bottom: 10px;
}

.deleteButton {
  top: 4px;
}

.updateButton {
  top: calc(50% - 4px);
}

.copyButton:hover,
.deleteButton:hover,
.updateButton:hover {
  cursor: pointer;

  color: #191919;

  background: #a2bcfc;
}

.copyButton:active,
.deleteButton:active,
.updateButton:active {
  color: #ffffff;

  background: #004ce8;
  border-color: #004ce8;
}

.image_wrapper {
  position: relative;

  max-width: 150px;
  max-height: 150px;
  margin-right: 12px;
  margin-bottom: 10px;

  border-radius: 2px;
}

.image_wrapper:hover {
  cursor: pointer;
}

.image {
  width: 128px;
  height: 72px;

  object-fit: cover;
  border-radius: 6px;
}

.image_size {
  position: absolute;

  padding: 1px;

  font-size: 12px;
  color: #191919;

  background: #ffffff;
  border-radius: 2px;
}
