.column {
  display: flex;
  flex-direction: column;
  align-items: normal !important;
  white-space: nowrap;
}

.mainContent {
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 27px;
}

.fixedHeightCell {
  composes: column;
  height: 40px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: var(--iconColor) !important;
}

.disabled {
  opacity: 0.5;
}
