.headerContainer {
  display: flex;
  white-space: nowrap;
  margin-bottom: 24px;
}

.search {
  margin-bottom: 20px;
}

.coursesFilter {
  margin: 0 0 24px 2px;
}
