.blockSearch {
  display: flex;

  width: 100%;
}
.blockSearch .currentSearch {
  width: 30%;
  /*border: 1px solid red;*/
}
.blockSearch .currentSearch:last-child {
  width: 40%;
  /*border: 1px solid red;*/
}
.slider {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: 60px;
}
.sliderInput {
  width: 46px;
  height: 36px;
  padding-left: 10px;

  color: var(--textColor);

  background: var(--bg_secondary);
  border: var(--main_border);
  border-radius: 2px;
}
.doubleSlider {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  margin: 0 12px;
}

.find {
  width: 100%;
  height: 36px;
  padding-left: 41px;

  color: var(--textColor);

  background: var(--bg_secondary);
  border: 1px solid #46515e;
  border-radius: 2px;
}
.findContainer {
  position: relative;

  flex-grow: 1;
}
.findContainer::before {
  content: '🔍';

  position: absolute;
  top: 8px;
  left: 12px;
}

.titlePage {
  margin-bottom: 17px;

  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
}
