.tableHeaderBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 50px;
    padding: 0 20px;

    border-bottom: 1px solid #e0e0e0;
}


.studentBox {
    display: flex;
    gap: 20px;
    align-items: center;

    width: 400px;
    height: 100%;
}