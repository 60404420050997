.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-x: hidden;
}

.flexContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}
