.pageContainer {
  height: 60vh;
}

.header {
  margin-bottom: 25px;
}

.content {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.datePicker {
  width: 250px !important;
}
