.mainContent {
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 27px;
}

.showOrHideButton {
  cursor: pointer;
  color: #1a75f5;
  text-decoration: underline;
}
