.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow: hidden;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  align-self: flex-end;
}
