.cellStatusDark {
    --bg_cell_status_wait: #311705;
    --bg_cell_status_in_work: #051731;
    --bg_cell_status_waiting: #2D0531;
    --bg_cell_status_accept: #052D1C;
    --bg_cell_status_re_wait: #310510;
    --border_cell_status_wait: 1px solid #C45E15;
    --border_cell_status_in_work: 1px solid #1A75F5;
    --border_cell_status_waiting: 1px solid #B615C4;
    --border_cell_status_accept: 1px solid #14B371;
    --border_cell_status_re_wait: 1px solid #C41541;
}

.cellStatusLight {
    --bg_cell_status_wait: #FDE3D1;
    --bg_cell_status_in_work: #D1E3FD;
    --bg_cell_status_waiting: #F9D1FD;
    --bg_cell_status_accept: #D1F9E8;
    --bg_cell_status_re_wait: #FDD1DC;
    --border_cell_status_wait: 1px solid #C45E15;
    --border_cell_status_in_work: 1px solid #155EC4;
    --border_cell_status_waiting: 1px solid #B615C4;
    --border_cell_status_accept: 1px solid #14B371;
    --border_cell_status_re_wait: 1px solid #C41541;
}

.cellStatusAll {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 19px;
}

.cellStatusWait {
    composes: cellStatusAll;
    background: var(--bg_cell_status_wait);
    border: var(--border_cell_status_wait);
}

.cellStatusInWork {
    composes: cellStatusAll;
    background: var(--bg_cell_status_in_work);
    border: var(--border_cell_status_in_work);
}

.cellStatusWaiting {
    composes: cellStatusAll;
    background: var(--bg_cell_status_waiting);
    border: var(--border_cell_status_waiting);
}

.cellStatusAccept {
    composes: cellStatusAll;
    background: var(--bg_cell_status_accept);
    border: var(--border_cell_status_accept);
}

.cellStatusReWait {
    composes: cellStatusAll;
    background: var(--bg_cell_status_re_wait);
    border: var(--border_cell_status_re_wait);
}

