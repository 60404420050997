.mainBlock {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
}
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 220px;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  margin-right: 60px;
}
.leftBlock div h2 {
  font-size: 50px;
  font-weight: 600;
  line-height: 68px;
}
.leftBlock div h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.button {
  max-width: 186px;
  height: 36px;

  color: white;

  background: #000000;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
  .block {
    flex-direction: column;
  }
  .leftBlock {
    order: 2;

    margin: 0;
  }
  .leftBlock div {
    margin-bottom: 16px;
  }
  .rightBlock {
    margin-bottom: 12px;
  }
}
