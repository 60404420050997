.redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*PageHeader 72px (92px in w=320px)*/
  /*Divider 49px*/
  min-height: calc(50vh - (92px + 49px));

  font-size: 20px;
}

.redirect .text {
  margin-right: 30px;
}
