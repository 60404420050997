.container {
  margin-bottom: 5px;
}

.text {
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
}
