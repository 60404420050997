.review {
  position: relative;

  width: 100%;
  max-width: 1075px;
  padding: 19px 24px 17px;
}

.reviewWrapper {
  display: flex;
  gap: 12px;
}

.avatar {
  width: 36px;
  height: 36px;
  margin-top: 5px;

  object-fit: cover;
  border-radius: 50%;
}

.reviewSubtitle {
  display: flex;
  gap: 15px;
}

.reviewTime {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.reviewText {
  width: 100%;
  white-space: pre-wrap;
  padding-right: 60px;
}

.icon {
  all: unset;

  cursor: pointer;

  position: absolute;
  top: 19px;
  right: 36px;

  width: 24px;
  height: 24px;
}

.likesContainer {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  margin-right: 10px;
}

.likeIco {
  all: unset;
}

.likesCountText {
  color: var(--color-neutral-dark-300) !important;
}

.dark-mode .likesCountText {
  color: var(--color-dark-mode-300) !important;
}
