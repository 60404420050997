.countOfStudents {
  display: flex;
  justify-content: flex-end;

  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.containerTable {
  margin-top: 5px;
}

.containerTable > .column {
  width: 100%;
  min-width: 970px;
}

.tableRow {
  display: flex;
  align-items: center;

  border: var(--table_border);
  border-top: none;

  background-color: var(--bg);
}

.column {
  padding: 12px 0 12px 25px;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.column:nth-child(3) {
  font-weight: 600;
}

.column:nth-child(7) {
  display: flex;
  gap: 5px;
  justify-content: center;

  padding-right: 20px;
}

.avatarCell {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hideButton {
  opacity: 0;
  pointer-events: none;
}
