.button {
  all: unset;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 12px 0 25px 18px;

  border-radius: 50%;

  &:focus-visible {
    outline: var(--outline-focus);
    outline-offset: 2px;
  }
}
