.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: normal;
  white-space: nowrap;
}

.dateAndCheckboxContainer {
  display: flex;
  align-items: center;
  height: 27px;
}

.redText {
  color: red;
}

.tooltip button {
  vertical-align: sub;
  margin-left: 4px;
}
