.backLink {
  display: flex;
  gap: 6px;
  align-items: center;

  height: var(--line-height-m);
  margin-bottom: 20px !important;
  color: var(--color-text-primary) !important;

  text-decoration: none !important;
}
