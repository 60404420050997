.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-between;

    margin-top: 40px;
}
